<template>
	<div class="project-status-dashboard card-shadow">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Order State</div>
			<v-spacer></v-spacer>
			<v-select
				class="cm-menu-select elevation-0 red--text"
				v-model="project_status"
				:items="projectStatusList"
				hide-details
				dense
				solo
			>
				<template v-slot:selection="{ item }">
					<span class="blue--text font-level-3-bold">{{ item }}</span>
				</template>
			</v-select>
		</v-layout>
		<div class="mx-auto px-4 py-3" style="max-width: 400px">
			<div>
				<apexchart
					type="donut"
					:options="chartOptions"
					:series="series"
					width="280px"
					class="chart-center"
				></apexchart>
			</div>
			<div class="project-desc">
				<ul class="pa-0 ul">
					<li class="d-flex li" v-for="(row, index) in chartData" :key="index">
						<div class="d-flex">
							<div :class="`status_color mr-3 ${row.status_color}`"></div>
							<div class="name text-truncate">{{ row.status }}</div>
						</div>
						<div class="project-number text-right">{{ row.order_no }}</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ProjectStatus",
	data() {
		return {
			project_status: "All Time",
			projectStatusList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
			series: [12, 55, 77],
			chartOptions: {
				chart: {
					width: 280,
					type: "donut",
				},
				legend: {
					position: "bottom",
					show: false,
				},
			},
			chartData: [
				{
					status_color: "green",
					status: "Order Completed",
					order_no: "12,502",
				},
				{
					status_color: "blue",
					status: "Order Processing",
					order_no: 32,
				},
				{
					status_color: "red",
					status: "Order Cancel",
					order_no: 50,
				},
			],
		};
	},
};
</script>
